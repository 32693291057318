// @flow
// $FlowFixMe
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  SUBSCRIPTION_PLANS,
  PLAN_ID_PREMIUM,
  PLAN_ID_PREMIUM_PLUS,
  PLAN_ID_PPC_1M,
  PLAN_ID_PPC_12M,
  DEFAULT_PLAN_ID,
} from '../../constants';

import {
  selectUserIsAuthenticated,
  selectIsStudentStripeCoupon,
  selectUserIsAllowedOptOutTrial,
  selectUserCanSubscribe,
  selectUserPlan,
} from '../../selectors/user';
import { selectDefaultPaymentType, selectOpenModalTypeIs } from '../../selectors/modals';
import {
  selectSubscriptionFromId,
  selectSubscriptionRelativeDiscount,
  selectSubscriptionReducedPriceDuration,
  selectSubscriptionTrialDurationDays,
} from '../../selectors/subscriptionOffer';

import * as analyticsActions from '../../actions/analytics';

import SignupStep from './SignupStep';
import ConversionFormInfo from './ConversionFormInfo';

import styles from './ConversionFormVisual.css';
import { selectIsTrialAvailable } from '../../selectors/subscription';
import PlansOverviewStep from './PlansOverviewStep';
import PaymentStep from './PaymentStep';
import BackButton from '../common/BackButton';
import Steps from './Steps';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { getWebsiteUrlInLocale } from '../../lib/locale-utils';
import { messages } from '../messages/Terms';
import { selectLocale } from '../../selectors/client';
import PaymentSuccessStep from './PaymentSuccessStep';
import * as discoverActions from '../../actions/discover';

declare var __ELECTRON__: boolean;

type OwnProps = {
  onComplete: Function,
  onClose: Function,
  onClickLoginLink?: Function,
  className?: string,
  location: Object,
};

type MapStateToProps = {
  userIsAuthenticated: boolean,
  userCanSubscribe: boolean,
  isInModal: boolean,
  preselectedPlan: PLAN_ID_PREMIUM | PLAN_ID_PREMIUM_PLUS | PLAN_ID_PPC_12M | PLAN_ID_PPC_1M,
  fromId: string,
  relativeDiscount?: number,
  reducedPriceDuration?: number,
  trialDurationDays: number,
  defaultPaymentType?: string,
  isStudentStripeCoupon: boolean,
  isTrialAvailable: boolean,
  userIsAllowedOptOutTrial: boolean,
  currentUserPlan: string,
  currentLocale: string,
};

type DispatchProps = {
  analyticsTrack: Function,
  loadDiscoverPage: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const SIGNUP_STEP = 1;
const PLANS_OVERVIEW_STEP = 2;
const PAYMENT_STEP = 3;
const PAYMENT_SUCCESS_STEP = 4;

const ANALYTICS_STEP_NAMES = {
  [SIGNUP_STEP]: 'AccountStep',
  [PLANS_OVERVIEW_STEP]: 'PlansOverviewStep',
  [PAYMENT_STEP]: 'PaymentStep',
  [PAYMENT_SUCCESS_STEP]: 'PaymentSuccessStep',
};

const SUPPORTED_PLANS = [PLAN_ID_PREMIUM, PLAN_ID_PREMIUM_PLUS, PLAN_ID_PPC_1M, PLAN_ID_PPC_12M];

const ConversionForm = ({
  userIsAuthenticated,
  userCanSubscribe,
  preselectedPlan,
  onComplete,
  onClose,
  onClickLoginLink,
  analyticsTrack,
  isInModal,
  isStudentStripeCoupon,
  isTrialAvailable,
  trialDurationDays,
  relativeDiscount,
  reducedPriceDuration,
  defaultPaymentType,
  fromId,
  userIsAllowedOptOutTrial,
  currentUserPlan,
  location,
  className,
  currentLocale,
  loadDiscoverPage,
}: Props) => {
  const [selectedPlan, setSelectedPlan] = useState(preselectedPlan);
  const [premiumPlusPlan, setPremiumPlusPlan] = useState(PLAN_ID_PREMIUM_PLUS);
  const [currentStep, setCurrentStep] = useState(null);
  const [totalSteps, setTotalSteps] = useState(3);

  useEffect(() => {
    // don't show the modal on desktop even if it's triggered. the subscribe button will open the webapp.
    if (__ELECTRON__) {
      onComplete();
      return;
    }

    if (!userIsAuthenticated) {
      setCurrentStep(SIGNUP_STEP);
      setTotalSteps(4);
      return;
    }

    if (userCanSubscribe) {
      if (!currentStep) {
        setCurrentStep(PLANS_OVERVIEW_STEP);
      }
      return;
    }

    if (!currentStep) {
      onComplete();
      return;
    }
  }, [userIsAuthenticated, userCanSubscribe, onComplete, currentStep]);

  useEffect(() => {
    const scrollToTop = () => {
      const scrollableArea = isInModal
        ? document.querySelector('[class*="Overlay__contentWrapper"]')
        : window;

      if (scrollableArea) {
        setTimeout(() => {
          scrollableArea.scrollTo({ top: 0, behavior: 'auto' });
        }, 0);
      }
    };

    scrollToTop();
  }, [currentStep, isInModal]);

  useEffect(() => {
    loadDiscoverPage('home');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSignupSuccess = () => {
    setCurrentStep(PLANS_OVERVIEW_STEP);
  };

  const proceedToPayment = () => {
    setCurrentStep(PAYMENT_STEP);
    analyticsTrack('Clicked CTA Button', {
      from: 'PlansOverviewStep',
      originPath: currentUserPlan,
      destinationPath: selectedPlan,
    });
  };

  const onPaymentSuccess = (plan, planId, currency) => {
    if (SUPPORTED_PLANS.includes(plan)) {
      const value = SUBSCRIPTION_PLANS[plan].totalPrice;
      analyticsTrack('Upgraded To Premium', {
        value,
        currency,
        subscriptionPrice: value,
        productId: planId,
      });
      setCurrentStep(PAYMENT_SUCCESS_STEP);
    }
  };

  const goBack = () => {
    if (currentStep - 1 < 2) {
      analyticsTrack('Closed Subscription Modal', {
        step: ANALYTICS_STEP_NAMES[currentStep],
      });
      onClose();
      return;
    }

    if (currentStep === PAYMENT_SUCCESS_STEP) {
      onComplete();
      return;
    }

    setCurrentStep(currentStep - 1);
    analyticsTrack('Closed Subscription Modal', {
      step: ANALYTICS_STEP_NAMES[currentStep],
      nextStep: ANALYTICS_STEP_NAMES[currentStep - 1],
    });
  };

  const price = selectedPlan ? SUBSCRIPTION_PLANS[selectedPlan].totalPrice : 0;

  return (
    <div
      className={classNames(styles.container, className, {
        [styles.fullHeightContainer]: currentStep !== SIGNUP_STEP,
      })}
    >
      <div className={styles.header}>
        <BackButton onClick={goBack} isVisible />
        <Steps currentStep={currentStep} totalSteps={totalSteps} />
      </div>
      {currentStep === SIGNUP_STEP && (
        <div className={styles.signupGrid} data-test="conversion-form.account-step-view">
          <ConversionFormInfo />
          <SignupStep
            expanded
            fromId={fromId}
            onClickLoginLink={onClickLoginLink}
            onSignupSuccess={onSignupSuccess}
          />
        </div>
      )}
      {currentStep === PLANS_OVERVIEW_STEP && (
        <PlansOverviewStep
          selectedPlan={selectedPlan}
          price={price}
          isStudentStripeCoupon={isStudentStripeCoupon}
          isTrialAvailable={isTrialAvailable}
          relativeDiscount={relativeDiscount}
          setSelectedPlan={setSelectedPlan}
          trialDurationDays={trialDurationDays}
          location={location}
          proceedToPayment={proceedToPayment}
          premiumPlusPlan={premiumPlusPlan}
          setPremiumPlusPlan={setPremiumPlusPlan}
        />
      )}
      {currentStep === PAYMENT_STEP && (
        <PaymentStep
          isInModal={isInModal}
          onSuccess={onPaymentSuccess}
          price={price}
          trialDurationDays={trialDurationDays}
          isTrialAvailable={isTrialAvailable}
          relativeDiscount={relativeDiscount}
          reducedPriceDuration={reducedPriceDuration}
          defaultPaymentType={defaultPaymentType}
          selectedPlan={selectedPlan}
          userIsAllowedOptOutTrial={userIsAllowedOptOutTrial}
          goBack={goBack}
        />
      )}
      {currentStep === PAYMENT_SUCCESS_STEP && (
        <PaymentSuccessStep onComplete={onComplete} isInModal={isInModal} />
      )}
      <div className={styles.footer}>
        <span>&copy; IDAGIO {new Date().getFullYear()}.</span>
        &nbsp;
        <FormattedMessage
          id="premium.conversion-form.footer.made-in-berlin"
          defaultMessage="Made in Berlin."
        />
        &nbsp;
        <FormattedMessage
          id="premium.conversion-form.footer.terms-privacy"
          defaultMessage="Please read our {termsLink} and {privacyPolicyLink}."
          values={{
            termsLink: (
              <a
                className="c-text-link--is-visible"
                href={getWebsiteUrlInLocale('/terms', currentLocale)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage {...messages.generalTerms} />
              </a>
            ),
            privacyPolicyLink: (
              <a
                className="c-text-link--is-visible"
                href={getWebsiteUrlInLocale('/privacy', currentLocale)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage {...messages.privacyPolicy} />
              </a>
            ),
          }}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state: Object, ownProps: OwnProps & { plan?: string }): MapStateToProps {
  const userIsAuthenticated = selectUserIsAuthenticated(state);

  const isInModal = selectOpenModalTypeIs(state, 'SUBSCRIBE_MODAL');

  const { location } = ownProps;

  let preselectedPlan = ownProps.plan || DEFAULT_PLAN_ID;
  if (location.query && location.query.plan) {
    const { plan } = location.query;
    if (SUPPORTED_PLANS.indexOf(plan) > -1) {
      preselectedPlan = plan;
    }
  }

  return {
    userIsAuthenticated,
    userCanSubscribe: selectUserCanSubscribe(state),
    isInModal,
    preselectedPlan,
    fromId: selectSubscriptionFromId(state),
    relativeDiscount: selectSubscriptionRelativeDiscount(state),
    reducedPriceDuration: selectSubscriptionReducedPriceDuration(state),
    trialDurationDays: selectSubscriptionTrialDurationDays(state),
    defaultPaymentType: selectDefaultPaymentType(state),
    isStudentStripeCoupon: selectIsStudentStripeCoupon(state),
    isTrialAvailable: selectIsTrialAvailable(state),
    userIsAllowedOptOutTrial: selectUserIsAllowedOptOutTrial(state),
    currentUserPlan: selectUserPlan(state),
    currentLocale: selectLocale(state),
  };
}

const dispatchProps: DispatchProps = {
  analyticsTrack: analyticsActions.track,
  loadDiscoverPage: discoverActions.loadDiscoverPage,
};

export default compose(withRouter, connect(mapStateToProps, dispatchProps))(ConversionForm);
